import dayjs from 'dayjs';

const {v4: uuidv4} = require('uuid');
const WorkModes = {
  Emergency: 0,
  Normal: 1
}

const initState = () => {
  console.log('call store: initState');
  
  if(window.name === '') {
    window.name = uuidv4()
  }
  return {
    currentTabId: window.name,
    registredTabId: null,
    registredTabId2: null,
    recentRegistredTabId: null,
    onlineTabId: null,
    phoneState: 'initial',
    registered: false,
    connectionRequested: false,
    session: null,
    outgoingCallInfo: null,
    sipSession: null,
    sipConnecting: false,
    sipReconnecting: false,
    mute: false,
    transferCall: false,
    transferGroups: [],
    callQueue: [],
    workMode: 0,
    keepAliveStarted: false
  }
}

const state = initState()

const getters = {
  hasCall: (state) => !!state.session,
  incomingAllowed: (state, _getters, _rootState, rootGetters) => {
    return (!state.sipSession && state.phoneState !== 'calling') &&
      (state.session == null || (state.workMode === WorkModes.Normal && rootGetters.getClientPackId != null))
  }
}

const mutations = {
  setRegistered(state, reg) {
    state.registered = reg
  },
  setSession(state, session) {
    console.log(`call store: mutation: setSession`);
    state.session = session
    if (!session)
      state.onlineTabId = null
    else
      state.onlineTabId = state.currentTabId
  },
  resetSession(state) {
    console.log(`call store: mutation: resetSession`);
    state.session = null
    state.onlineTabId = null
    state.outgoingCallInfo = null
    this._vm.$sip.processQueue()
  },
  resetRegistredState(state) {
    if (state.currentTabId === state.registredTabId) {
      state.registredTabId = null
    }
  },
  setSipSession(state, sipSession) {
    state.sipSession = sipSession
  },
  setPhoneState(state, phoneState) {
    state.phoneState = phoneState
  },
  setMute(state, mute) {
    state.mute = mute
  },
  setSipConnecting(state, sipConnecting) {
    state.sipConnecting = sipConnecting
  },
  setSipReconnecting(state, sipReconnecting) {
    state.sipReconnecting = sipReconnecting
  },
  setConnectionRequested(state, connectionRequested) {
    state.connectionRequested = connectionRequested
  },
  setTransferCall(state, transfer) {
    state.transferCall = transfer
  },
  setTransferGroups(state, transferGroups) {
    state.transferGroups = transferGroups
  },
  setRegstredTabId(state, tabId) {
    state.registredTabId = tabId
  },
  setRecentRegistredTabId(state, tabId) {
    state.recentRegistredTabId = tabId
  },
  addToCallQueue(state, item) {
    state.callQueue.push(item)
  },
  removeFromCallQueue(state, item) {
    state.callQueue = state.callQueue.filter(i => i.id !== item.id)
  },
  popFromCallQueue(state) {
    let callFromQueue = state.callQueue.shift()
    if (callFromQueue != null) {
      this._vm.$sip.processQueueItem(callFromQueue)
    }
  },
  setOutgoingCallInfo(state, info) {
    state.outgoingCallInfo = info
  },
  setCallStartedDate(state, date) {
    if (date) {
      state.session.call_started = dayjs(date)?.format('YYYY-MM-DDTHH:mm:ssZ')
    } else {
      state.session.call_started = date
    }
  },
  setCallFinishedDate(state, date) {
    if (date) {
      state.session.call_finished = dayjs(date)?.format('YYYY-MM-DDTHH:mm:ssZ')
    } else {
      state.session.call_finished = date
    }
  },
  setWorkMode(state, mode) {
    state.workMode = mode
  },
  setKeepAliveStarted(state, val) {
    state.keepAliveStarted = val
  },
  setTransferDestination(state, alias) {
    state.session.transferTo = alias
  },
}

const actions = {
  setRegistered(ctx, reg) {
    ctx.commit('setRegistered', reg)
    if (reg) {
      ctx.commit('setRegstredTabId', state.currentTabId);
      ctx.commit('setRecentRegistredTabId', state.currentTabId)
    }
  },
  setSipSession(ctx, sipSession) {
    ctx.commit('setSipSession', sipSession)
  },
  setSession(ctx, session) {
    console.log(`call store: action: setSession`);
    ctx.commit('setSession', session)
  },
  resetSession(ctx) {
    console.log(`call store: action: resetSession`);
    ctx.commit('resetSession')
  },
  async sipConnect(ctx) {
    ctx.commit('setConnectionRequested', true);
    ctx.commit('setSipConnecting', true);
    setTimeout(async () => {
      await ctx.dispatch('sipConnectInternal')
    }, 0);
  },

  async sipConnectInternal(ctx, silently = false)
  {
    try {

      let getCredentialsResponse = await this._vm.$api.get('switch-credentials/');
      if (getCredentialsResponse.status === 200 && getCredentialsResponse.data.user_id) {
        if (!getCredentialsResponse.data.user_id) {
          this._vm.$notify(({
            type: 'error',
            title: 'Не удалось выйти на линию',
            text: 'Пользователь не зарегистрирован'
          }))
        } else if (!getCredentialsResponse.data.secret) {
          if(!silently)
            this._vm.$notify(({type: 'error', title: 'Не удалось выйти на линию', text: 'Некорректные учётные данные'}));
        } else {
          // активируем sip учётку
          await this._vm.$api.get('switch-active/');
          // аутентифицируемся в атс
          await this._vm.$sip.authAsync(getCredentialsResponse.data.user_id, getCredentialsResponse.data.secret);
          if (ctx.state.keepAliveStarted === false) {
            ctx.commit('setKeepAliveStarted', true)
            ctx.dispatch('autoKeepAlive')
          }
        }
      } else {
        if(!silently)
          this._vm.$notify(({
            type: 'error',
            title: 'Не удалось выйти на линию',
            text: 'Ошибка при получении учётных данных'
          }));
        return;
      }

      try {
        let getTransferGroupsResponse = await this._vm.$api.get('call-forwarding-group/');
        if (getTransferGroupsResponse.status === 200) {
          ctx.commit('setTransferGroups', getTransferGroupsResponse.data);
        }

      } catch {
        if(!silently)
          this._vm.$notify(({type: 'warn', text: 'Не удалось получить список групп переадресации'}))
      }
      
      try {
        let getWorkModeResponse = await this._vm.$api.get('work-mode/1/')
        if (getWorkModeResponse.status === 200) {
          ctx.commit('setWorkMode', getWorkModeResponse.data.mode)
        }
      } catch {
        if(!silently)
          this._vm.$notify(({type: 'warn', text: 'Не удалось определить режим работы'}));
      }

      
    } catch (ex) {
      if(!silently)
        this._vm.$notify(({type: 'error', title: 'Не удалось выйти на линию', text: 'Попробуйте ещё раз'}));
      throw new Error(ex);
    } finally {
      ctx.commit('setSipConnecting', false);
    }
  },

  async sipDisconnect({commit}) {
    commit('setConnectionRequested', false);
    commit('setSipConnecting', true);
    commit('setKeepAliveStarted', false)
    
    setTimeout(async () => {
      try {
        commit('setSipReconnecting', false);
        await this._vm.$sip.disconnect();
      } catch {
        // push some notification
      } finally {
        commit('setSipConnecting', false);
      }
    }, 0)
  },
  async initOutgoingCall(ctx, number) {
    try {
      let now = dayjs(new Date())?.format('YYYY-MM-DDTHH:mm:ssZ')
      let resp = await this._vm.$api.post('call/', {
        'phone_number': {
          'phone_number': number
        },
        'incoming': false,
        'call_started': now
      })
      let outgoingCallId = resp.data.id
      if (number && resp.status === 201) {
        this._vm.$sip.call(number, [`X-Call-ID: ${outgoingCallId}`])
      }
      ctx.commit('setOutgoingCallInfo', {
        callId: outgoingCallId,
        phoneNumber: number,
        fullName: resp.data.assumed_caller,
        clientId: resp.data.client_id
      })
    } catch (ex) {
      throw new Error(ex);
    }

  },
  async initTestCall(ctx) {
    try {
      ctx.commit('setOutgoingCallInfo', {
        phoneNumber: 'Echo Test',
        fullName: 'Echo Test Caller',
        isTest: true
      })
      this._vm.$sip.test_call()
    } catch (ex) {
      throw new Error(ex);
    }

  },
  async autoKeepAlive(ctx, timeout = 1000 * 30) {
    try {
      if (ctx.state.registered) {
        await this._vm.$api.get('switch-live/')
      }
    } catch (ex) {
      throw new Error(ex);
    } finally {
      if (ctx.state.keepAliveStarted === true) {
        setTimeout(() => (ctx.dispatch('autoKeepAlive')), timeout);
      }
    }
  },
  async getClientPackByLatestSession(ctx){
    try {
      let clientId = ctx.state.session?.client_id;
      if(!clientId)
        return null;
        
      let getSessionResponse = await this._vm.$api.get(`session/${clientId}/latest_client_session/`);
      let latestSession = getSessionResponse.data?.find((s) => s.end_time);
      
      if(!latestSession || !latestSession?.client_pack?.id)
        return null;
      
      let isOutOfDate = Date.parse(latestSession.end_time) < Date.now() - 5*60*1000;

      if(isOutOfDate)
        return null;

      return latestSession.client_pack.id
    } catch (error) {
      return null
    }
  },

  async sipReconnect(ctx){
    ctx.commit('setSipReconnecting', true);
    ctx.dispatch('autoReconnect');
  },

  async autoReconnect(ctx, timeout = 1000 * 30) {
    if(ctx.state.sipReconnecting === false)
      return;
    try {
      ctx.commit('setSipConnecting', true);
      if (!ctx.state.registered && ctx.state.sipReconnecting) {
        await ctx.dispatch('sipConnectInternal', true);
      }
    } catch (ex) {
      throw new Error(ex);
    } finally {

      ctx.commit('setSipConnecting', false);
      
      if (!ctx.state.registered) {
        setTimeout(() => (ctx.dispatch('autoReconnect')), timeout);
      } else {
        ctx.commit('setSipReconnecting', false)
      }
    }
  },

  async sipRestoreConnection(ctx) {
    if(ctx.state.recentRegistredTabId !== ctx.state.currentTabId)
      return;
    
    ctx.commit('setConnectionRequested', true);
    await ctx.dispatch('sipReconnect');

  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
